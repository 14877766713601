import React from 'react';
// import { benefitCols } from '../constants/EmployeeCols';

import ReactTable from '../components/ReactTable';
import { useBenefitCols } from '../hooks/EmployeeCols';

export default function Benefits(props) {
  const benefitCols = useBenefitCols();
  if (!props.data) return null;
  return <ReactTable cols={benefitCols} data={props.data} />;
}
