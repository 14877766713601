import { currencyFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const useCustomerInvoiceCols = () => {
  const transDateCol = useDateRangeCol('TRANS_DATE', 'Trans Date');
  const dueDateCol = useDateRangeCol('DUE_DATE', 'Due Date');

  const customerInvoiceCols = [
    {
      accessorKey: 'TRANS_TYPE',
      header: 'Trans Type',
      filterFn: 'contains',
    },
    {
      accessorKey: 'INVOICE',
      header: 'Invoice',
      filterFn: 'contains',
    },
    {
      accessorKey: 'PAYMENT_SEQ',
      header: 'Payment Seq',
      filterFn: 'contains',
    },
    {
      accessorKey: 'STATUS',
      header: 'Status',
      filterFn: 'contains',
    },
    transDateCol,
    dueDateCol,
    {
      accessorKey: 'TRAN_AMT',
      header: 'Tran Amount',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'APPLD_AMT',
      header: 'Applied Amount',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'OPEN_AMT',
      header: 'Open Amount',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'PAYMENT_VIEW',
      header: 'Veiw',
    },
  ];

  return customerInvoiceCols;
};

export { useCustomerInvoiceCols };
