import useDateRangeCol from './useDateRangeCol';

const useRequisitionInquiryReactTableCols = () => {
  const reqDelDateCol = useDateRangeCol('REQ_DEL_DATE', 'Date');

  const requisitionInquiryReactTableCols = [
    {
      accessorKey: 'LINE_NBR',
      header: 'Line',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ITEM',
      header: 'Item',
      filterFn: 'contains',
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Description',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ITEM_TYPE',
      header: 'Type',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ENTERED_OUM',
      header: 'UOM',
      filterFn: 'contains',
    },
    {
      accessorKey: 'QUANTITY',
      header: 'Qty',
      filterFn: 'contains',
    },
    {
      accessorKey: 'STATUS',
      header: 'Status',
      filterFn: 'contains',
    },
    reqDelDateCol,
    {
      accessorKey: 'DELIVER_TO',
      header: 'Deliver To',
      filterFn: 'contains',
    },
    {
      accessorKey: 'PO_LINK',
      header: 'PO Source',
      enableColumnFilter: false,
    },
  ];

  return requisitionInquiryReactTableCols;
};

export { useRequisitionInquiryReactTableCols };
