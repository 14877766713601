import React from 'react'
import { TableCell } from '@material-ui/core'
import { currencyFormat, noWrapFormat, dateFormat } from '../utils/formatter'

const columnStyleWithWidth = {
  top: '0px',
  left: '0px',
  zIndex: '100',
  position: 'sticky',
  backgroundColor: '#fff',
  width: '800px'
}

export const actransCols = [
  {
    name: 'FISCAL_YEAR',
    label: 'Year',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PERIOD',
    label: 'Prd',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Co',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
        customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },

  {
    name: 'ACTIVITY_GRP',
    label: 'Activity Group',
    options: {
        customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },

  {
    name: 'ACCT_CATEGORY',
    label: 'Account Cat',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_DATE',
    label: 'Tran Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'POSTING_DATE',
    label: 'Posting Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Acct Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Acct',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Desc',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_AMOUNT',
    label: 'Amt',
    options: {
      customBodyRender: currencyFormat,
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      filter: true,
      sort: true
    }
  },
  {
    name: 'MORE',
    label: 'More',
    options: {
      filter: false,
      sort: false,
      download: false
    }
  }
]

export const actransMoreCols = [
  {
    name: 'FROM_COMPANY',
    label: 'From Comp',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'UNITS_AMOUNT',
    label: 'Unit Amt',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_CURRENCY',
    label: 'Currency',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'OPERATOR',
    label: 'Operation',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CONTROL_GROUP',
    label: 'Control Group',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SYSTEM',
    label: 'System',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const actransReactTableCols = [
  {
    accessorKey: 'FISCAL_YEAR',
    header: 'Year',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'PERIOD',
    header: 'Prd',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'COMPANY',
    header: 'Co',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'ACTIVITY',
    header: 'Activity',
    size: 1,
    enableSorting: false,
    enableColumnFilter: true
  },

  {
    accessorKey: 'ACTIVITY_GRP',
    header: 'Activity Group',
    size: 1,
    enableSorting: false,
    enableColumnFilter: true
  },

  {
    accessorKey: 'ACCT_CATEGORY',
    header: 'Account Cat',
    size: 1,
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'TRAN_DATE',
    header: 'Tran Date',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'POSTING_DATE',
    header: 'Posting Date',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'ACCT_UNIT',
    header: 'Acct Unit',
    size: 1,
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'ACCOUNT',
    header: 'Account',
    size: 1,
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'SUB_ACCOUNT',
    header: 'Sub Acct',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Desc',
    size: 1,
    enableSorting: false,
    enableColumnFilter: true
  },
  {
    accessorKey: 'TRAN_AMOUNT',
    header: 'Amt',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'FROM_COMPANY',
    header: 'From Comp',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'UNITS_AMOUNT',
    header: 'Unit Amt',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'TRAN_CURRENCY',
    header: 'Currency',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'SOURCE_CODE',
    header: 'Source Code',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'OPERATOR',
    header: 'Operation',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'CONTROL_GROUP',
    header: 'Control Group',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'TRANS_TYPE',
    header: 'Type',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'STATUS',
    header: 'Status',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    accessorKey: 'SYSTEM',
    header: 'System',
    size: 1,
    enableSorting: false,
    enableColumnFilter: false
  }
]

export const billCostCols = [
  {
    name: 'ACTIVITY_GROUP',
    label: 'Activity Group',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'LEVEL',
    label: 'Level',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'POSTING_DATE',
    label: 'Posting Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'SYSTEM',
    label: 'System',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SOURCE_CODE',
    label: 'Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Co',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Acct',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Desc',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACT_CURRENCY',
    label: 'Currency',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACT_AMOUNT',
    label: 'Amt',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'UNIT_AMOUNT',
    label: 'Unit Amt',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const billofCostAcParams = [
  {
    "accessorKey": "ACTIVITY_GROUP",
    "header": "Activity Group",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "ACTIVITY",
    "header": "Activity",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "LEVEL",
    "header": "Level",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "ACCT_CATEGORY",
    "header": "Category",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "POSTING_DATE",
    "header": "Posting Date",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1,
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    "accessorKey": "SYSTEM",
    "header": "System",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "SOURCE_CODE",
    "header": "Code",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "COMPANY",
    "header": "Co",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "ACCOUNT",
    "header": "Acct",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "DESCRIPTION",
    "header": "Desc",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "ACT_CURRENCY",
    "header": "Currency",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  },
  {
    "accessorKey": "ACT_AMOUNT",
    "header": "Amt",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1,
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    "accessorKey": "UNIT_AMOUNT",
    "header": "Unit Amt",
    enableSorting: false,
    enableColumnFilter: false,
    "size": 1
  }
]