import React, { useEffect } from 'react'
import { withSnackbar } from 'notistack'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  withStyles,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  InputAdornment,
  Box,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'

import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { AntTabsVert, AntTab } from '../components/common/Tabs'
import LoadingCircle from '../components/common/LoadingCircle'
import { createMuiTheme } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid'
import LazyLoadingAutocomplete from '../functions/LazyLoadingAutocomplete'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import SeverSideMultiAutoComplete from '../functions/SeverSideMultiAutoComplete'
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'left'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#E86613'
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#E86613',
      opacity: 1
    },
    '&$selected': {
      color: '#E86613'
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none'
    },
    '&:active': {
      outline: 'none'
    }
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginLeft: 15,
    width: 'min-content',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)'
    }
  }
})

function Reports (props) {
  const [state, setState] = React.useState({
    currentReport: null,
    currentReportDropdowns: {},
    reports: [],
    isLoading: true,
    paramOpen: false,
    paramDropDowns: false,
    isSubmitting: false,
    tableCol: null,
    tableData: null,
    tabs: [],
    tabValue: 0,
    openPrintDialog: false,
    reportData: []
  })

  const [reportParams, setReportParams] = React.useState({})
  useEffect(() => {
    async function fetch () {
      try {
        const resp = await LambdaFetch(
          'reports',
          'post',
          props.fetchInitialData.credentials.user.accessToken,
          JSON.stringify({
            action: 'get-reports'
          }),
          '',
          props.fetchInitialData.credentials
        )

        if (resp.success) {
          const data = resp.data
          let tabs = []
          data.reports.forEach(report => {
            if (tabs.indexOf(report.category) === -1) {
              tabs.push(report.category)
            }
          })

          setState({
            ...state,
            reports: data.reports,
            isLoading: false,
            tabs: tabs.sort()
          })
        } else {
          setState({ ...state, isLoading: false }, () => {
            createSnack('There was a error grabbing the reports', 'error', 3500)
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createSnack = (message, type, duration) => {
    props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      variant: type,
      autoHideDuration: duration
    })
  }

  const handleClose = () => {
    setState({
      ...state,
      currentReport: null,
      currentReportDropdowns: {},
      paramOpen: false,
      paramDropDowns: false
    })
    setReportParams({})
  }
  const handleRedirect = href => {
    window.open(href, '_blank')
  }
  const getOptions = async (queries, report) => {
    try {
      setState({
        ...state,
        currentReport: report
      })
      const resp = await LambdaFetch(
        'reports',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'basic-query',
          ...{
            queries: queries
          }
        }),
        '',
        props.fetchInitialData.credentials
      )
      if (resp.success) {
        const data = resp.data

        setState({
          ...state,
          dropdownData: data.myResults,
          currentReport: report,
          paramOpen: true,
          paramDropDowns: true
        })
        for (let dropdown in data.myResults) {
          onTagsChange(null, data.myResults[dropdown][0], dropdown)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleCurrentReport = report => {
    let myQueries = JSON.parse(report.parameters).reduce((acc, field) => {
      if (field.fieldType === 'dynamicList') {
        return [...acc, { id: field.name, query: field.query }]
      } else return acc
    }, [])
    if (myQueries.length > 0) {
      getOptions(myQueries, report)
    } else {
      setState({
        ...state,
        currentReport: report,
        paramOpen: true,
        paramDropDowns: true
      })
    }

    let reportParams = JSON.parse(report.parameters).reduce((acc, field) => {
      acc[field.name] = null
      return acc
    }, {})
    setReportParams(reportParams)
  }

  const runReport = async event => {
    event.preventDefault()
    try {
      const { user } = props.fetchInitialData.credentials

      setState({ ...state, isSubmitting: true })
      let parameters = JSON.parse(state.currentReport.parameters)
      let paramsConditions = []

      const reportParameterObj = parameters.reduce((acc, cur) => {
        if (
          cur.fieldType === 'autocomplete-all' ||
          cur.fieldType === 'autocomplete-serverside' ||
          cur.fieldType === 'smart-autocomplete'
        ) {
          acc[cur.name] = reportParams[cur.name] ? reportParams[cur.name] : ''
        } else if (cur.fieldType === 'date') {
          acc[cur.name] = event.target[cur.name].value
        } else if (cur.fieldType === 'dynamicList') {
          acc[cur.name] = reportParams[cur.name].value
        } else {
          acc[cur.name] = event.target[cur.name].value
        }
        return acc
      }, {})

      const reportingParams = parameters.reduce((acc, cur) => {
        if (cur.fieldType === 'autocomplete-all') {
          acc[cur.displayName] = event.target[cur.name].value
        } else if (cur.fieldType === 'smart-autocomplete') {
          acc[cur.name] = reportParams[cur.name] ? reportParams[cur.name] : ''
        } else if (cur.fieldType === 'date') {
          acc[cur.displayName] = event.target[cur.name].value
        } else if (cur.fieldType === 'dynamicList') {
          acc[cur.displayName] = reportParams[cur.name].value
        } else {
          acc[cur.displayName] = event.target[cur.name].value
        }
        return acc
      }, {})

      let params = parameters.map(p => {
        return reportParameterObj[p.name]
      })

      let storedProc = parameters.reduce((acc, param, index) => {
        return (acc += index + 1 === params.length ? `?)` : `?,`)
      }, `call ${process.env.REACT_APP_DATABASE_NAME}.${state.currentReport.storedproc}(?,?,?,?,`)

      if (parameters.length === 0) {
        storedProc = storedProc.slice(0, -1).concat(')')
      }
      let fetchData = {
        jsonWebTok: props.fetchInitialData.credentials.user.accessToken,
        report_name: state.currentReport.report_name,
        storedProc,
        params: [
          user.username,
          state.currentReport.report_name,
          uuidv4(),
          event.target.jobName.value,
          ...params
        ],
        reportingParams: JSON.stringify(reportingParams),
        storedProcName: state.currentReport.storedproc
      }

      const resp = await LambdaFetch(
        'reports',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'run-report-v2',
          ...fetchData
        }),
        '',
        props.fetchInitialData.credentials,
        true
      )

      if (resp.success) {
        setState({
          ...state,
          isSubmitting: false,
          paramOpen: false,
          paramDropDowns: false
        })
        props.fetchInitialData.createSnack(
          'Successfully ran report',
          'success',
          3000
        )
      } else {
        setState({ ...state, isSubmitting: false, paramOpen: false })
        props.fetchInitialData.createSnack(
          'Running report. May take a few minutes to show up in print manager',
          'warning',
          5000
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onTagsChange = (event, values, name) => {
    setReportParams({ ...reportParams, [name]: values })
  }
  const getMuiTheme = () => {
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            backgroundColor: '#FFF'
          }
        }
      }
    })
  }

  const { reports, isLoading, currentReport, paramOpen, paramDropDowns } = state

  if (isLoading) {
    return <LoadingCircle />
  }

  const { classes } = props
  let tabs = []
  if (state.reports) {
    tabs = state.reports.forEach(report => {
      if (tabs.indexOf(report.category) === -1) {
        tabs.push(reports.category)
      }
    })
  }
  return (
    <div>
      {paramOpen && (
        <Dialog
          fullWidth
          scroll={'paper'}
          open={paramOpen}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{currentReport.report_name}</DialogTitle>
          <DialogContent dividers>
            <form id='report-form' onSubmit={runReport}>
              <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id={'jobName'}
                    required={true}
                    label={'Job Name'}
                    variant='filled'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <span />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                {JSON.parse(currentReport.parameters).map((p, index) => {
                  if (p.fieldType === 'dynamicList') {
                    return (
                      <Grid key={index} item xs={12} sm={12} md={12}>
                        <LazyLoadingAutocomplete
                          onChange={val => onTagsChange(null, val, p.name)}
                          id={p.displayName}
                          name={p.displayName}
                          required={p.isRequired}
                          variant='filled'
                          label={p.displayName}
                          options={
                            state.dropdownData[p.name]
                              ? state.dropdownData[p.name]
                              : []
                          }
                          renderInput={params => (
                            <TextField
                              required
                              {...params}
                              label={p.displayName}
                              variant='filled'
                            />
                          )}
                        />
                      </Grid>
                    )
                  } else if (p.fieldType === 'smart-autocomplete') {
                    const isDisabled = p.dependsOn
                      ? p.dependsOn.split(',').reduce((acc, cur) => {
                          if (!reportParams[cur]) {
                            acc = true
                          }
                          return acc
                        }, false)
                      : false

                    const storedProc = p.procedureParams
                      .split(',')
                      .reduce((acc, param, index) => {
                        return (acc +=
                          index + 1 === p.procedureParams.split(',').length
                            ? `?)`
                            : `?,`)
                      }, `call ${process.env.REACT_APP_DATABASE_NAME}.${p.procedure}(?,?,`)

                    const reportingParams = p.procedureParams
                      .split(',')
                      .reduce((acc, cur) => {
                        return [
                          ...acc,
                          reportParams[cur] ? reportParams[cur] : -1
                        ]
                      }, [])

                    if (p.multiSelect) {
                      return (
                        <SeverSideMultiAutoComplete
                          required={p.isRequired === 'True' || p.isRequired}
                          id={p.name}
                          label={p.displayName}
                          disabled={isDisabled}
                          dontAutoHighlight
                          dontOpenOnFocus
                          fullWidth
                          margin={'standard'}
                          padding={'8px'}
                          accessToken={
                            props.fetchInitialData.credentials.user.accessToken
                          }
                          error={`No ${p.displayName} Found`}
                          variant='filled'
                          handleChange={(id, hit) => {
                            setReportParams({ ...reportParams, [p.name]: id })
                          }}
                          credentials={props.fetchInitialData.credentials}
                          apiResource='reports'
                          secondaryParams={{
                            lookupCol: p.name,
                            params: reportingParams,
                            storedProc: storedProc,
                            action: 'run-reports-autocomplete'
                          }}
                        />
                      )
                    } else {
                      return (<ServerSideAutoCompletev2
                        required={p.isRequired === 'True' || p.isRequired}
                        id={p.name}
                        label={p.displayName}
                        disabled={isDisabled}
                        dontAutoHighlight
                        dontOpenOnFocus
                        fullWidth
                        margin={'standard'}
                        padding={'8px'}
                        accessToken={
                          props.fetchInitialData.credentials.user.accessToken
                        }
                        error={`No ${p.displayName} Found`}
                        variant='filled'
                        handleChange={(id, hit) => {
                          setReportParams({ ...reportParams, [p.name]: id })
                        }}
                        credentials={props.fetchInitialData.credentials}
                        apiResource='reports'
                        secondaryParams={{
                          lookupCol: p.name,
                          params: reportingParams,
                          storedProc: storedProc,
                          action: 'run-reports-autocomplete'
                        }}
                      />)
                    }
                  } else if (p.fieldType === 'autocomplete') {
                    return (
                      <ServerSideAutoCompletev2
                        required={p.isRequired === 'True' || p.isRequired}
                        id={p.name}
                        label={p.displayName}
                        dontAutoHighlight
                        dontOpenOnFocus
                        fullWidth
                        margin={'standard'}
                        padding={'8px'}
                        accessToken={
                          props.fetchInitialData.credentials.user.accessToken
                        }
                        error={`No ${p.displayName} Found`}
                        variant='filled'
                        handleChange={(id, hit) => {
                          setReportParams({ ...reportParams, [p.name]: id })
                        }}
                        credentials={props.fetchInitialData.credentials}
                        apiResource='reports'
                        secondaryParams={{
                          category: p.category,
                          table: p.table,
                          action: 'autocomplete-select'
                        }}
                      />
                    )
                  } else if (p.fieldType === 'autocomplete-serverside') {
                    return (
                      <ServerSideAutoCompletev2
                        required={p.isRequired === 'True' || p.isRequired}
                        id={p.name}
                        label={p.displayName}
                        dontAutoHighlight
                        dontOpenOnFocus
                        fullWidth
                        margin={'standard'}
                        padding={'8px'}
                        accessToken={
                          props.fetchInitialData.credentials.user.accessToken
                        }
                        error={`No ${p.displayName} Found`}
                        variant='filled'
                        handleChange={(id, hit) => {
                          onTagsChange(null, id, p.name)
                        }}
                        credentials={props.fetchInitialData.credentials}
                        apiResource='autocomplete'
                        secondaryParams={{
                          category: p.autoCategory,
                          dropdown: p.dropdown,
                          action: 'autocomplete-reports-select'
                        }}
                      />
                    )
                  } else if (p.fieldType === 'finiteList') {
                    return (
                      <Grid key={index} item xs={12} sm={12} md={12}>
                        <Autocomplete
                          required
                          getOptionLabel={option => option}
                          id={p.name}
                          options={p.list ? p.list.split(',') : []}
                          renderInput={params => (
                            <TextField
                              required
                              {...params}
                              label={p.displayName}
                              variant='filled'
                            />
                          )}
                        />
                      </Grid>
                    )
                  } else if (p.fieldType === 'autocomplete-all') {
                    return (
                      <Grid key={index} item xs={12} sm={12} md={12}>
                        <LazyLoadingAutocomplete
                          {...props}
                          onChange={val => {
                            setReportParams({
                              ...reportParams,
                              [p.name]: val.key_value
                            })
                          }}
                          id={p.name}
                          name={p.name}
                          required={p.isRequired}
                          variant='filled'
                          label={p.displayName}
                          autoCategory={p.autoCategory}
                          dropdown={p.dropdown}
                          renderInput={params => (
                            <TextField
                              required
                              {...params}
                              label={p.displayName}
                              variant='filled'
                            />
                          )}
                        />
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid key={index} item xs={12} sm={12} md={12}>
                        <TextField
                          id={p.name}
                          type={p.fieldType}
                          required={p.isRequired === 'True' || p.isRequired}
                          label={p.displayName}
                          variant='filled'
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <span />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    )
                  }
                })}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>

            <LoadingButton
              form='report-form'
              label='Confirm'
              isLoading={state.isSubmitting}
              color='primaryVLButton'
              buttonType='submit'
            />
          </DialogActions>
        </Dialog>
      )}
      <div className='paymentForm'>
        <Paper style={{ margin: 'auto', maxWidth: '900px' }}>
          <div className={classes.root}>
            <AntTabsVert
              color={props.fetchInitialData.credentials.primaryAppColor}
              orientation='vertical'
              variant='scrollable'
              value={state.tabValue}
              onChange={(event, newValue) =>
                setState({ ...state, tabValue: newValue })
              }
              aria-label='Vertical tabs example'
              style={{ width: '120px' }}
            >
              {state.tabs.map((tab, index) => {
                return (
                  <AntTab
                    color={props.fetchInitialData.credentials.primaryAppColor}
                    key={index}
                    label={tab}
                    value={index}
                  />
                )
              })}
            </AntTabsVert>
            {state.tabs.map((tab, index) => {
              return (
                <TabPanel
                  key={index}
                  style={{
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: '0.5rem',
                    width: '100%'
                  }}
                  value={state.tabValue}
                  index={index}
                >
                  <List
                    style={{ width: '100%' }}
                    dense
                    aria-label='main mailbox folders'
                  >
                    {reports
                      .filter(report => report.category === tab)
                      .sort((a, b) => a.report_name - b.report_name)
                      .map((row, index) => {
                        return (
                          <ListItem
                            button
                            style={{ minWidth: '100%' }}
                            onClick={() => handleCurrentReport(row)}
                            key={index}
                          >
                            <ListItemText
                              primary={row.report_name}
                              secondary={row.description}
                            />
                          </ListItem>
                        )
                      })}
                  </List>
                </TabPanel>
              )
            })}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default withStyles(styles)(withSnackbar(Reports))

function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingLeft: '1rem' }}>{children}</Box>}
    </div>
  )
}
