import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
  table: {
    fontSize: '12px'
  },
  tableHeader: {}
})

export default function SimpleTable (props) {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table className={classes.table} padding="none" size={props.dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {props.headers.map(h => {
              return <TableCell key={h}>{h}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow key={index}>
              {props.headers.map((h,i) => {
                return <TableCell key={`${index},${i}`}>{row[h]}</TableCell>
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
