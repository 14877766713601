import React, { useState } from 'react'
import TableMui from './TableMui'
import moment from 'moment-timezone'
import LambdaFetch from '../functions/FetchFromLambda'
import getTableOptions from '../constants/TableOptions'
import DialogTable from './DialogTable'
import {
  invoiceCols,
  invoiceWithVendorCols,
  invoiceDistCols,
  invoicePaymentCols,
  activityCols
} from '../constants/VendorCols'
import PoDetailDialog from './PoDetailDialog'
import {dateFormat} from '../utils/formatter';

export function VendorInvoices (props) {
  const [objInfo, setObjInfo] = useState(null)
  const [payment, setPayment] = useState(null)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [activityInfo, setActivityInfo] = useState(null)
  const handleClose = () => {
    setObjInfo(null)
    setPayment(null)
    setSelectedInvoice(null)
    setActivityInfo(null)
  }
  const [poData, setpoData] = useState({poHeaderData: null, poDetailData: null})
  const getDistribData = async objId => {
    try {
      const resp = await LambdaFetch(
        'vendors',
        'post',
        props.accessToken,
        JSON.stringify({
          action: 'invoice-distrib-read',
          objId
        }),
        null,
        props.credentials
      )
      if (resp.success) {
        setObjInfo(
          resp.data.distrib.map(item => {
            return [
              item.DIST_SEQ_NBR,
              dateFormat(new Date(item.DISTRIB_DATE)),
              item.DESCRIPTION,
              item.DIS_ACCT_UNIT,
              `${item.DIS_ACCOUNT}${
                item.DIS_SUB_ACCT ? `-${item.DIS_SUB_ACCT}` : ''
              }`,
              item.ACTIVITY ? (
                <div className="editLink" onClick={() => {
                  const win = window.open(`/actrans?id=${item.ATN_OBJ_ID}&type=OBJ_ID`, "_blank");
                  win.focus();
              }}>{item.ACTIVITY}</div>
              ) : (
                ''
              ),
              item.ORIG_BASE_AMT
            ]
          })
        )
      } else {
        setObjInfo(null)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getPo = async (id, comp,code) => {
    try {
      if (!id || !comp) return null
      setpoData({...poData, open: true})
      const resp = await LambdaFetch(
        'po',
        'post',
        props.credentials.user.accessToken,
        JSON.stringify({
          action: 'get-po',
          company: comp,
          po: id,
          code: code
        }),
        '',
        props.credentials
      )

      setpoData({
        ...poData,
        poHeaderData: resp.data.poHeader[0],
        poDetailData: resp.data.poDetail,
        open: true
      })
    } catch (e) {
      console.log(e)
    }
  }
  const getPaymentData = async (inv, comp) => {
    try {
      const resp = await LambdaFetch(
        'vendors',
        'post',
        props.accessToken,
        JSON.stringify({
          action: 'invoice-payment-read',
          vendor: props.vendor,
          invoice: inv.trim(),
          company: comp
        }),
        null,
        props.credentials
      )
      const payments = resp.data.payment.map(p => {
        p['STATUS'] = `${p.REC_STATUS_DESC} (${p.REC_STATUS})`
        p['PAYMENT_CODE'] = `${p.BANK_INST_DESC} (${p.BANK_INST_CODE})`
        return p
      })
      if (resp.success) {
        setPayment(payments)
      } else {
        setObjInfo(null)
      }
    } catch (e) {
      console.log(e)
    }
  }

  if (!props.data) return null

	const options = getTableOptions({cols: invoiceCols, fileName: props.fileName});

  const tableData = props.data.map(row => {
    let curRow = [
      row.COMPANY,
      `${row.INVOICE}${row.SUFFIX ? `-${row.SUFFIX}` : ''}`,
      row.INVOICE_TYPE,
      row.VOUCHER_NBR.trim(),
      row.INVOICE_DTE,
      row.BASE_INV_AMT,
      row.TRAN_INV_AMT,
      row.DESCRIPTION,
      row.DUE_DATE,
      row.PO_NUMBER ? <div
        className='editLink'
        onClick={() => {
          getPo(row.PO_NUMBER, row.COMPANY, row.PO_CODE)
        }}
      >{`${row.PO_CODE && `${row.PO_CODE} - `}${row.PO_NUMBER.trim()}${row.PO_RELEASE && ` - ${row.PO_RELEASE}`}`}
      </div> : '',
      <div
        className='editLink'
        onClick={() => {
          getDistribData(row.OBJ_ID)
          setSelectedInvoice(row.INVOICE)
        }}
      >
        view
      </div>,
      <div
        className='editLink'
        onClick={() => {
          getPaymentData(row.INVOICE, row.COMPANY)
          setSelectedInvoice(row.INVOICE)
        }}
      >
        view
      </div>,
            row.OPERATOR
    ]

    if(props.showVendor) {
      curRow = [row.VENDOR, row.VENDOR_VNAME, ...curRow]
    }
    return curRow
  })

  const invCols = props.showVendor ? invoiceWithVendorCols : invoiceCols
  return (
    <>
       <PoDetailDialog
        {...props}
        poHeaderData={poData.poHeaderData}
        poDetailData={poData.poDetailData}
        open={poData.open}
        close={() =>
          setpoData({ ...poData, poHeaderData: null, poDetailData: null, open: false })
        }
      />
    
      <DialogTable
        tableTitle={selectedInvoice}
        isOpen={!!payment}
        handleClose={handleClose}
        tableData={payment}
        cols={invoicePaymentCols}
        fileName={`${props.fileName}_Payments_${selectedInvoice}`}
        noFooter
      />

      <DialogTable
        tableTitle={selectedInvoice}
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={invoiceDistCols}
        totalColIndex={6}
        fileName={`${props.fileName}_Distribution_${selectedInvoice}`}
      />
       <DialogTable
        isOpen={!!activityInfo}
        handleClose={() => setActivityInfo(null)}
        tableData={activityInfo}
        cols={activityCols}
        simpleTable={true}
      />
      <TableMui
        cols={invCols}
        data={tableData}
        options={options}
        title=''
      />
    </>
  )
}
