import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { dateFormat } from '../utils/formatter'

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { prDistirbCols } from '../constants/EmployeeCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import ReactTable from '../components/ReactTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

export default function MetadataHelp (props) {
  const classes = useStyles()

  const [state, setstate] = useState({
    isLoading: false,
    categories: [],
    data: null,
    tableKey: null
  })
  const [showTable, setShowTable] = useState(false)
  const [category, setCategory] = useState(null)

  const { credentials } = props.fetchInitialData

  useEffect(() => {
    async function fetchMyAPI () {
      fetchMetaHrd()
    }
    fetchMyAPI()
  }, [])

  const fetchMetaHrd = async () => {
    try {
      const resp = await LambdaFetch(
        'meta-data',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-meta-data-hdr'
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        categories: resp.data.categories
      })
    } catch (e) {
      console.log(e)
    }
  }
  const onTagChange = (event, value) => {
    if (value) {
      setCategory(value)
      fetchMetadataDtl(value.NAME)
    } else {
      setCategory(null)
    }
  }
  const handleSubmit = async e => {
    e.preventDefault()
    setShowTable(true)
  }

  const fetchMetadataDtl = async category => {
    setstate({...state, isLoading: true})
    try {
      const resp = await LambdaFetch(
        'meta-data',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-meta-data-dtl',
          category
        }),
        '',
        credentials
      )

      setstate({
        ...state,
        data: resp.data.metadataDtl,
        isLoading: false,
        tableKey: category
      })
    } catch (e) {
      console.log(e)
      setstate({ ...state, isLoading: false })
    }
  }
  const paintCols = () => {
    let cols = [
      {
        accessorKey: 'CODE',
        header: 'CODE',
        filterFn: 'contains'
      },
      {
        accessorKey: 'DESCRIPTION',
        header: 'DESCRIPTION',
        filterFn: 'contains'
      },
      {
        accessorKey: 'START_DATE',
        header: 'START_DATE',
        filterFn: 'contains',
        Cell: ({ cell }) => dateFormat(cell.getValue())
      },
       {
        accessorKey: 'END_DATE',
        header: 'END_DATE',
        filterFn: 'contains',
        Cell: ({ cell }) => dateFormat(cell.getValue())
      },
      {
        accessorKey: 'ERP_STATUS',
        header: 'STATUS',
        filterFn: 'contains'
      }
    ]
    if (category.KEY1) {
      cols.push({
        accessorKey: 'KEY1',
        header: category.KEY1,
        filterFn: 'contains'
      })
    }
    if (category.KEY2) {
      cols.push({
        accessorKey: 'KEY2',
        header: category.KEY2,
        filterFn: 'contains'
      })
    }
    if (category.KEY3) {
      cols.push({
        accessorKey: 'KEY3',
        header: category.KEY3,
        filterFn: 'contains'
      })
    }
    return cols
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Metadata
      </Typography>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id='cateogry'
              options={state.categories}
              getOptionLabel={option => option.DESCRIPTION}
              filterSelectedOptions
              autoHighlight
              autoSelect
              onChange={(event, values) => onTagChange(event, values)}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  margin='dense'
                  label='Category'
                  variant='outlined'
                />
              )}
            />
            <div style={{ margin: '1rem 0' }}>
              {!!category && (
                <ReactTable
                isLoading={state.isLoading}
                  key={state.tableKey}
                  cols={paintCols()}
                  data={state.data}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
