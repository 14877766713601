import React from 'react'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import {
  locationCols
} from '../constants/VendorCols'

export default function VendorLocations (props) {
  if (!props.data) return null
	const options = getTableOptions({cols: locationCols, fileName: props.fileName});

  return (
    <>
      <TableMui
        cols={locationCols}
        data={props.data}
        options={options}
        title=''
      />
    </>
  )
}
