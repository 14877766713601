import React, { useEffect } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LambdaFetch from '../functions/FetchFromLambda'

export default function SimpleAutoComplete (props) {
  const [inputVal, setInputVal] = React.useState('')
  const [list, setList] = React.useState([])
  const onTagsChange = (event, values) => {
    props.onChange(values)
  }

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    setInputVal('')
  }, [props.clear])

  const fetchData = async id => {
    try {
      const resp = await LambdaFetch(
        'autocomplete',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'get-values',
          screen: props.screen,
          category: props.category
        }),
        '',
        props.fetchInitialData.credentials
      )
      setList(resp.data.results)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Autocomplete
      id={props.id}
      name={props.name}
      autoSelect={true}
      autoHighlight={true}
      openOnFocus={true}
      inputValue={inputVal}
      onInputChange={(event, value) => setInputVal(value)}
      options={list}
      getOptionLabel={val => (val ? val.display.toString() : '')}
      style={{ width: props.width ? props.width : 300 }}
      onChange={onTagsChange}
      disabled={props.disabled}
      renderInput={params => {
        return (
          <TextField
            {...params}
            required={props.required}
            label={props.label}
            value={props.value}
            margin='dense'
            variant={props.variant ? props.variant : 'outlined'}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position='start'>
                    <div></div>
                  </InputAdornment>
                </>
              )
            }}
          />
        )
      }}
    />
  )
}
