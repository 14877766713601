import { currencyFormat, dateFormat } from '../utils/formatter'

export const cashBookTransCols = [
  {
    name: 'CASH_CODE',
    label: 'Cash Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BANK_INST_CODE',
    label: 'Payment',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_NBR',
    label: 'Transaction',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ISSUE_DATE',
    label: 'Issue Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ISSUE_BNK_AMT',
    label: 'Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BNK_TRANS_SIGN',
    label: 'Sign',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REC_STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  }
]

export const cashBookTransReactTableCols = [
  {
    accessorKey: 'CASH_CODE',
    header: 'Cash Code',
    filterFn: 'contains'
  },
  {
    accessorKey: 'BANK_INST_CODE',
    header: 'Payment',
    filterFn: 'contains'
  },
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains'
  },
  {
    accessorKey: 'TRANS_NBR',
    header: 'Transaction',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ISSUE_DATE',
    header: 'Issue Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ISSUE_BNK_AMT',
    header: 'Amount',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'BNK_TRANS_SIGN',
    header: 'Sign',
    filterFn: 'contains'
  },
  {
    accessorKey: 'REC_STATUS',
    header: 'Status',
    filterFn: 'contains'
  }
]

export const cashBookTransServerSideCols = [
  {
    accessorKey: 'CASH_CODE',
    header: 'Cash Code',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains'
  },
  {
    accessorKey: 'BANK_INST_CODE',
    header: 'Payment',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains'
  },
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    enableSorting: false,
    enableColumnFilter: true,
    filterFn: 'contains'
  },
  {
    accessorKey: 'TRANS_NBR',
    header: 'Transaction',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains'
  },
  {
    accessorKey: 'ISSUE_DATE',
    header: 'Issue Date',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    enableSorting: false,
    enableColumnFilter: true,
    filterFn: 'contains'
  },
  {
    accessorKey: 'ISSUE_BNK_AMT',
    header: 'Amount',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'BNK_TRANS_SIGN',
    header: 'Sign',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains'
  },
  {
    accessorKey: 'REC_STATUS',
    header: 'Status',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains'
  },
  {
    accessorKey: 'RECON_DATE',
    header: 'Recon Date',
    enableSorting: false,
    enableColumnFilter: false,
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'VIEW_DISTRIB',
    header: 'Distribution',
    enableSorting: false,
    enableColumnFilter: false,
    },
  
]


export const cashBookDistribCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DIS_ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DIS_ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DIS_SUB_ACCT',
    label: 'Sub Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'POST_DATE',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      customBodyRender: dateFormat
    }
  },
  {
    name: 'DST_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BANK_DIST_AMT',
    label: 'Amount',
    options: {
      filter: true,
      sort: true,
      customBodyRender: currencyFormat
    }
  }
]

export const cashBookPaymentInquirysServerSideCols = [
{
  accessorKey: 'COMPANY', 
  header: 'Company',
  enableSorting: false,
  enableColumnFilter: true
},
{
  accessorKey: 'CASH_CODE', 
  header: 'Cash Code',
  enableSorting: false,
  enableColumnFilter: true
},
{
  accessorKey: 'BANK_INST_CODE', 
  header: 'Inst Code',
  enableSorting: false,
  enableColumnFilter: true
},
{
  accessorKey: 'SOURCE_CODE', 
  header: 'Code',
  enableSorting: false,
  enableColumnFilter: true
},
{
  accessorKey: 'REC_STATUS',
  header: 'Status',
  enableSorting: false,
  enableColumnFilter: true,
  filterVariant: 'select',
  filterSelectOptions: [
    'All',
    'Historical',
    'Unreleased',
    'Released',
    'Selected for Payment',
    'System Generated',
    'Voided'
  ]
},
{
  accessorKey: 'TRANS_NBR',
  header: 'Trans Number',
  enableSorting: false,
  enableColumnFilter: true
},
{
  accessorKey: 'TRANS_IDENT',
  header: 'Trans Ident',
  enableSorting: false,
  enableColumnFilter: true
},
{
  accessorKey: 'CHECK_DATE',
  header: 'Date',
  enableSorting: false,
  enableColumnFilter: false,
  Cell: ({ cell }) => dateFormat(cell.getValue())
},
{
  accessorKey: 'BANK_CHK_AMT',
  header: 'Amount',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAYEE_NAME',
  header: 'Payee Name',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAYEE_ADDR1',
  header: 'Addr1',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAYEE_ADDR2',
  header: 'Addr2',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAYEE_ADDR3',
  header: 'Addr 3',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAYEE_ADDR4',
  header: 'Addr 4',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'CITY',
  header: 'City',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAYEE_ST_PROV',
  header: 'State',
  enableSorting: false,
  enableColumnFilter: false
},
{
  accessorKey: 'PAY_POSTAL_CD',
  header: 'Postal',
  enableSorting: false,
  enableColumnFilter: false
}
]

export const apPaymentCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'VENDOR',
    label: 'Vendor',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE',
    label: 'Invoice',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SEQ_NBR',
    label: 'Sequence',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE_DTE',
    label: 'Invoice Date',
    options: {
      filter: true,
      sort: true,
      customBodyRender: dateFormat
    }
  },
  {
    name: 'TRAN_PMT_AMT',
    label: 'Amount',
    options: {
      filter: true,
      sort: true,
      customBodyRender: currencyFormat
    }
  }
]