import React, {useEffect,useRef} from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default function SimpleAutoComplete (props) {
  const didMountRef = useRef(false)
  const [inputVal, setInputVal] = React.useState(props.defaultVal ? props.defaultVal : '')
  const onTagsChange = (event, values) => {
    props.onChange(values)
  }

  useEffect(() => {
      setInputVal('')
  }, [props.clear])

  return (
    <Autocomplete
      id={props.id}
      name={props.name}
      autoSelect={true}
      autoHighlight={true}
      openOnFocus={true}
      inputValue={inputVal}
      onInputChange={(event, value ) => setInputVal(value)}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      renderOption={props.renderOption}
      style={{ width: props.width ? props.width : 300}}
      onChange={onTagsChange}
      disabled={props.disabled}
      renderInput={params => {
        return (
          <TextField
            {...params}
            required={props.required}
            label={props.label}
            value={props.value}
            margin='dense'
            variant={props.variant ? props.variant : 'outlined'}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position='start'>
                    <div></div>
                  </InputAdornment>
                </>
              )
            }}
          />
        )
      }}
    />
  )
}
