import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default function LoadingCircle (props) {
  return (
    <div style={{ height: props.height ? props.height : '100vh' }}>
      <div
        style={{ position: 'relative', left: '50%', top: '40%', width: '60px' }}
      >
        <CircularProgress className='loading-circle' disableShrink />
      </div>
    </div>
  )
}
