import { currencyFormat, dateFormat } from '../utils/formatter';

export const icTransCols = [
  {
    name: 'TRANS_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'HIST_YEAR',
    label: 'Year',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'HIST_PERIOD',
    label: 'Period',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SYSTEM_CD',
    label: 'System',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'TYPE_DESC',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'TRAN_UOM',
    label: 'UOM',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'LINE_NBR',
    label: 'Line',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'UNIT_COST',
    label: 'Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'viewMore',
    label: 'More',
    options: {
      download: false,
      filter: true,
      sort: true,
    },
  },
  ...[
    { name: 'DOCUMENT', label: 'Document' },
    { name: 'ORIGINAL_QTY', label: 'Original Qty' },
    { name: 'TRAN_UOM_MULT', label: 'UOM Mult' },
    { name: 'STOCK_UOM', label: 'UOM Mult' },
    { name: 'REASON_CODE', label: 'Reason Code' },
    { name: 'UNIT_COST_CURR', label: 'Current Cost' },
    { name: 'ADD_UNIT_COST', label: 'Additional Unit Cost' },
    { name: 'BIN', label: 'Bin' },
    { name: 'FROM_TO_BIN', label: 'From To Bin' },
    { name: 'FROM_TO_LOC', label: 'From To Loc' },
  ].map((c) => {
    return {
      name: c.name,
      label: c.label,
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    };
  }),
];

export const icTransReactTableCols = [
  {
    accessorKey: 'TRANS_DATE',
    header: 'Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue()),
  },
  {
    accessorKey: 'HIST_YEAR',
    header: 'Year',
    filterFn: 'contains',
  },
  {
    accessorKey: 'HIST_PERIOD',
    header: 'Period',
    filterFn: 'contains',
  },
  {
    accessorKey: 'SYSTEM_CD',
    header: 'System',
    filterFn: 'contains',
  },
  {
    accessorKey: 'TYPE_DESC',
    header: 'Type',
    filterFn: 'contains',
  },
  {
    accessorKey: 'QUANTITY',
    header: 'Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'TRAN_UOM',
    header: 'UOM',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LINE_NBR',
    header: 'Line',
    filterFn: 'contains',
  },
  {
    accessorKey: 'UNIT_COST',
    header: 'Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
  { accessorKey: 'DOCUMENT', header: 'Document', filterFn: 'contains' },
  { accessorKey: 'ORIGINAL_QTY', header: 'Original Qty', filterFn: 'contains' },
  { accessorKey: 'TRAN_UOM_MULT', header: 'UOM Mult', filterFn: 'contains' },
  { accessorKey: 'STOCK_UOM', header: 'UOM Mult', filterFn: 'contains' },
  { accessorKey: 'REASON_CODE', header: 'Reason Code', filterFn: 'contains' },
  {
    accessorKey: 'UNIT_COST_CURR',
    header: 'Current Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
  {
    accessorKey: 'ADD_UNIT_COST',
    header: 'Additional Unit Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
  { accessorKey: 'BIN', header: 'Bin', filterFn: 'contains' },
  { accessorKey: 'FROM_TO_BIN', header: 'From To Bin', filterFn: 'contains' },
  { accessorKey: 'FROM_TO_LOC', header: 'From To Loc', filterFn: 'contains' },
];
