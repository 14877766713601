import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  TableFooter,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Button
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { trialBalanceConsolidatedCols } from '../constants/GlCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import clsx from 'clsx'
import { currencyTextFormat } from '../utils/formatter'
import ReactTable from '../components/ReactTable'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import ReactTableServerSide from '../components/ReactTableServerSide'
import AutocompleteDropDown from '../components/AutocompleteDropdown'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: '-1px',
    zIndex: 100
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0'
    }
  }
}))

const transTypes = [
  {
    id: 'A',
    description: 'Amount'
  },
  {
    id: 'U',
    description: 'Unit'
  },
  {
    id: 'B',
    description: 'Budget'
  },
  {
    id: 'C',
    description: 'Currency'
  },
  {
    id: 'N',
    description: 'Budget Unit'
  },
  {
    id: 'Y',
    description: 'Currency One'
  },
  {
    id: 'Z',
    description: 'Currency Two'
  }
]
const defaultHeaders = {
  fiscalYear: '',
  company: '',
  period: '',
  type: 'A'
}

export default function TrialBalanceConsolidated (props) {
  const [state, setstate] = useState({
    options: null,
    isLoading: false,
    data: null,
    fetched: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [showTable, setShowTable] = useState(false)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setstate({ ...state, isLoading: true })
      const currentType = transTypes.find(option => option.description === e.target.type.value).id
      const resp = await LambdaFetch(
        'trial-balance',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-trial-balances-consolidated',
          ...headers,
          type: currentType
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        data: resp.data.trialBalancesConsolidated,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    console.log('callback')
    setstate({ ...state, isLoading: false })
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Trial Balances Consolidated
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'company'}
                  width='150px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'Year'}
                  width='150px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'period'}
                  name={'period'}
                  width='150px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period'}
                  value={headers.period}
                  category={'period'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      period: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id='type'
                  options={transTypes}
                  getOptionLabel={option => option.description}
                  defaultValue={transTypes[0]}
                  style={{ width: '150px' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='standard'
                      margin='dense'
                      label='Type'
                    />
                  )}
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(headers.fiscalYear || headers.period || headers.company) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={trialBalanceConsolidatedCols} data={state.data} />
          </Grid>
        )}
      </div>
    </div>
  )
}
