import React, { useEffect, useState } from 'react'
import { Typography, Card, CardContent, Grid, Paper } from '@material-ui/core'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { makeStyles } from '@material-ui/core/styles'
import { AntTabs } from '../components/VendorTabs'
import {
  itemCols,
  bookCols,
  itemReactTableCols,
  bookReactTableCols
} from '../constants/AssetCols'
import { printValue } from '../utils/PrintValue'
import ReactTable from '../components/ReactTable'
import { useBookCols, useItemCols } from '../hooks/AssetCols';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem',
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%',
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' },
}));

export default function AssetDetail(props) {
  const classes = useStyles();

  const [tabVal, setTabVal] = useState(0);
  const { credentials } = props.fetchInitialData;
  const { assetData, items, books } = props;

  const itemReactTableCols = useItemCols();
  const bookReactTableCols = useBookCols();

  const itemOptions = getTableOptions({
    cols: itemCols,
    fileName: `Asset_Items_${assetData && assetData.ASSET}`,
  });

  const bookOptions = getTableOptions({
    cols: bookCols,
    fileName: `Asset_Book_${assetData && assetData.ASSET}`,
  });

  return (
    <Card style={{ marginTop: '1rem' }}>
      <CardContent style={{ position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            marginTop: '0.5rem',
            justifyContent: 'flex-start',
          }}
        >
          <div>
            <Typography variant='h5'>{`Asset: ${assetData.ASSET} (${assetData.ASSET_DESC})`}</Typography>
            <Typography variant='subtitle1'>{`Tag: ${assetData.TAG_NBR}`}</Typography>
            <Typography variant='subtitle2'>{`Asset Group: ${assetData.ASSET_GROUP}`}</Typography>
            <Typography variant='caption'>{`Category: ${assetData.ASSET_CATEGORY}`}</Typography>
          </div>
        </div>

        <AntTabs
          value={tabVal}
          color={credentials.primaryAppColor}
          handleChange={(event, newValue) => {
            setTabVal(newValue);
          }}
          tabs={['Asset', 'Items', 'Book']}
        />

        <div style={{ marginTop: '1rem' }}>
          {tabVal === 0 && (
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12} sm={12} md={12}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5">Main</Typography>

                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="space-evenly"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} sm={6}>
                      {printValue(assetData, 'COMPANY', 'Company')}
                      {printValue(assetData, 'LOCATION_NAME', 'Location')}
                      {printValue(assetData, 'DIVISION', 'Division')}
                      {printValue(assetData, 'ASSET_TYPE', 'Type', 'SUB_TYPE')}
                      {printValue(assetData, 'ACCT_GRP', 'Account Group')}
                      {printValue(
                        assetData,
                        'AU_GROUP',
                        'Accounting Unit Group'
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {printValue(assetData, 'ACCT_UNIT', 'Accounting Unit')}
                      {printValue(
                        assetData,
                        'DEP_EXP_ACTVTY',
                        'Depr Expense Activity',
                        'DEP_ACCT_CAT'
                      )}
                      {printValue(assetData, 'CURRENCY_CODE', 'Currency')}
                      {printValue(assetData, 'ASSET_OWNER', 'Owner')}
                      {printValue(assetData, 'ACCT_UNIT', 'Accounting Unit')}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {tabVal === 1 && (
            <div>
              <ReactTable cols={itemReactTableCols} data={items} />
            </div>
          )}
          {tabVal === 2 && (
            <div>
              <ReactTable
                cols={bookReactTableCols}
                data={books.map((b) => {
                  return { ...b, BOOK_VALUE: b.BOOK_BASIS - b.DEPR_LTD };
                })}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
