import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import getTableOptions from '../constants/TableOptions';
// import { checkCols } from '../constants/EmployeeCols'
import LambdaFetch from '../functions/FetchFromLambda';
import PayStubDetails from '../components/PaystubDetails';
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dateFormat } from '../utils/formatter';
import ReactTable from '../components/ReactTable';
import { useCheckCols } from '../hooks/EmployeeCols';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    // boxShadow: 'none',
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    // '&:before': {
    //   display: 'none',
    // },
    // '&$expanded': {
    //   margin: 'auto',
    // },
  },
  tabsummary: {
    backgroundColor: 'rgba(0, 0, 0, 0.025)',
  },
  appBar: {
    position: 'relative',
  },
  accordionDets: {
    display: 'block',
  },
}));

export default function EmployeeChecks(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [checkDetails, setCheckDetails] = React.useState(null);

  const checkCols = useCheckCols();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClose = () => {
    setCheckDetails(null);
  };

  const getCheckDetails = async (checkId) => {
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'paystub_detail',
        empId: props.empId,
        objId: checkId,
        company: props.company,
      })
    );
    if (resp.success) {
      const data = resp.data;
      data['main'] = props.data.find((check) => check.CHECK_ID === checkId);

      setCheckDetails(data);
    } else {
      setCheckDetails(null);
    }
  };

  if (!props.data) return null;

  const yrs = props.data.reduce((yrs, cur) => {
    if (yrs.indexOf(cur.YEAR) === -1) {
      return [...yrs, cur.YEAR];
    } else {
      return yrs;
    }
  }, []);
  return (
    <div>
      {checkDetails && (
        <PayStubDetails
          {...props}
          handleClose={handleClose}
          checkDetails={checkDetails}
        />
      )}

      {yrs.sort().map((yr) => {
        const tableData = props.data
          .filter((r) => r.YEAR === yr)
          .map((row) => {
            return {
              ...row,
              CHECK_VIEW: (
                <div
                  className="editLink"
                  onClick={() => getCheckDetails(row.CHECK_ID)}
                >
                  {row.CHECK_ID}
                </div>
              ),
            };
          });

        return (
          <div key={yr}>
            <Accordion
              square
              expanded={expanded === yr}
              onChange={handleChange(yr)}
            >
              <AccordionSummary
                className={classes.tabsummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>{yr}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDets}>
                <ReactTable cols={checkCols} data={tableData} />
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}
