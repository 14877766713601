import React from 'react';
// import { payRateHistCols } from '../constants/EmployeeCols';
import ReactTable from '../components/ReactTable';
import { usePayRateHistCols } from '../hooks/EmployeeCols';

export default function PayRateHistory(props) {
  const payRateHistCols = usePayRateHistCols();

  if (!props.data) return null;

  return <ReactTable cols={payRateHistCols} data={props.data} />;
}
