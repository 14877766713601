import { currencyFormat, dateFormat } from '../utils/formatter'

export const customerActivityCols = [
  {
    name: 'trans_type',
    label: 'Trans Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'invoice',
    label: 'Invoice',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'trans_date',
    label: 'Trans Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'due_date',
    label: 'Due Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'tran_amt',
    label: 'Tran Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'open_amt',
    label: 'Open Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  }
]

export const customerInvoiceCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains'
  },
  {
    accessorKey: 'CUSTOMER',
    header: 'Customer',
    filterFn: 'contains'
  },
  {
    accessorKey: 'TRANS_TYPE',
    header: 'Trans Type',
    filterFn: 'contains'
  },
  {
    accessorKey: 'INVOICE',
    header: 'Invoice',
    filterFn: 'contains'
  },
  {
    accessorKey: 'PAYMENT_SEQ',
    header: 'Payment Seq',
    filterFn: 'contains'
  },
  {
    accessorKey: 'STATUS',
    header: 'Status',
    filterFn: 'contains'
  },
  {
    accessorKey: 'TRANS_DATE',
    header: 'Trans Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'DUE_DATE',
    header: 'Due Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'TRAN_AMT',
    header: 'Tran Amount',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'APPLD_AMT',
    header: 'Applied Amount',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'OPEN_AMT',
    header: 'Open Amount',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'PAYMENT_VIEW',
    header: 'Payment'
  },
  {
    accessorKey: 'DISTRIB_VIEW',
    header: 'Distrib'
  }
]

export const paymentCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INVOICE',
    label: 'Invoice',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PAYMENT_SEQ',
    label: 'Seq',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BATCH_NBR',
    label: 'Batch Num',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DEPOSIT_DATE',
    label: 'Deposit Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'POST_DATE',
    label: 'Post Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'APPLD_AMT',
    label: 'Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  }
]

export const customerDistribCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUB_ACCT',
    label: 'Sub Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_DATE',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      customBodyRender: dateFormat
    }
  },
  {
    name: 'INVOICE',
    label: 'Invoice',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DIST_SEQ',
    label: 'Seq',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DST_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESC',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_AMT',
    label: 'Amount',
    options: {
      filter: true,
      sort: true,
      customBodyRender: currencyFormat
    }
  }
]
