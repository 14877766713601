import { debounceSearchRender } from "../functions/DebounceSearchRender";

const getTableOptions = (props) => {
  const options = {
    setRowProps: (row, dataIndex, rowIndex) => {
      if (rowIndex % 2 === 0) {
        return {
          style: { background: '#F7F7F7' },
        };
      } else {
        return {
          style: { background: '#FFFFFF' },
        };
      }
    },
    customSort: (data, colIndex, order) => {
      let dateCols = props.cols.reduce((dateCols, currCol, index) => {
        if (
          currCol.name.toLowerCase().indexOf('date') !== -1 ||
          currCol.name.toLowerCase().indexOf('upload') !== -1
        ) {
          return [...dateCols, index];
        } else return dateCols;
      }, []);
      return data.sort((a, b) => {
        if (dateCols.indexOf(colIndex) !== -1) {
          return (
            (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) *
            (order === 'desc' ? 1 : -1)
          );
        } else {
          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === 'desc' ? 1 : -1)
          );
        }
      });
    },
    customSearchRender: debounceSearchRender(500),
    responsive: 'standard',
    fixedHeader: true,
    rowHover: false,
    search: true,
    print: false,
    filter: true,
    viewColumns: false,
    tableBodyMaxHeight:'620px',
    download: true,
    rowsPerPageOptions: [5,10,20,50,100],
    displaySelectToolbar: false,
    elevation: 0,
    selectableRows: 'none',
    filterType: 'multiselect',
    downloadOptions: {
      filename: props.fileName ? `${props.fileName.replaceAll(' ','_').replaceAll('.','')}.csv` : "tableData.csv",
      filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true
      }
      },
  };

  return options;
};

export default getTableOptions;
