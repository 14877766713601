import React from 'react';
import { Grid } from '@material-ui/core';

import NumberFormat from 'react-number-format';
// import { reviewCols, certificatesCols } from '../constants/EmployeeCols'
import { AntTabs, AntTab } from '../components/common/Tabs';
import ReactTable from '../components/ReactTable';
import PersonalActHistory from '../components/PersonalActHisotry';
import { useCertificatesCols, useReviewCols } from '../hooks/EmployeeCols';

export default function PersonalActions(props) {
  const [value, setValue] = React.useState(0);

  const reviewCols = useReviewCols();
  const certificatesCols = useCertificatesCols();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <AntTabs
              color={props.fetchInitialData.credentials.primaryAppColor}
              value={value}
              onChange={handleChange}
              aria-label="ant example"
            >
              <AntTab
                label="History"
                value={0}
                color={props.fetchInitialData.credentials.primaryAppColor}
              />
              <AntTab
                label="Reviews"
                value={1}
                color={props.fetchInitialData.credentials.primaryAppColor}
              />
              <AntTab
                label="Certifications"
                value={2}
                color={props.fetchInitialData.credentials.primaryAppColor}
              />
            </AntTabs>
            {value === 0 && (
              <div>
                <PersonalActHistory
                  data={props.data.actions}
                  empId={props.empId}
                  fileName={`Personnel_Action_History_${props.employee.FIRST_NAME}_${props.employee.LAST_NAME}`}
                  company={props.company}
                  accessToken={
                    props.fetchInitialData.credentials.user.accessToken
                  }
                  fetchInitialData={props.fetchInitialData}
                />
              </div>
            )}
            {value === 1 && (
              <div>
                <ReactTable cols={reviewCols} data={props.data.reviews} />
              </div>
            )}
            {value === 2 && (
              <div>
                <ReactTable
                  cols={certificatesCols}
                  data={props.data.certificates}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function statistic(val, label, prefix) {
  return (
    <div style={{ textAlign: 'center', margin: '0 1rem' }}>
      <div style={{ fontSize: '0.9rem' }}>{label}</div>
      <NumberFormat
        value={(Math.round(val * 100) / 100).toFixed(2)}
        thousandSeparator={true}
        decimalScale={2}
        displayType="text"
        prefix={prefix}
      />
    </div>
  );
}
