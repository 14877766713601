import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import { cashBookPaymentInquirysServerSideCols,cashBookDistribCols } from '../constants/CashCodeCols'
import { smallScreenWidth } from '../constants/AppConstants'
import LoadingButton from '../components/LoadingButton'
import ReactTableServerSide from '../components/ReactTableServerSide'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

const defaultHeaders = {
  comapny: '',
  fromDate: '',
  toDate: ''
}

export default function CashPaymentInquiry (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    isLoading: false,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [showTable, setShowTable] = useState(false)
  const [clearHeader, setClearHeaders] = React.useState(false);

  const handleSubmitTrans = e => {
    e.preventDefault()
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }

  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false });
      setShowTable(false);
      return null;
    }
    setstate({ ...state, isLoading: false });
  };

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Cash Payment Inquiry (CB 56)
      </Typography>



      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmitTrans}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={2}
            >
              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      style={{ marginBottom: '5px', width: '160px' }}
                      type={'date'}
                      required={true}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action='cash-payment-inquiry'
              apiResource={'cash-book'}
              tableTitle={''}
              callback={handleCallback}
              tableColumns={cashBookPaymentInquirysServerSideCols}
              noPagination
              downloadAction={'cash-payment-inquiry-download'}
              downloadFileName={'Cashbook_Payment_inquiry_Download'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
