import { currencyFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const useIcTransReactTableCols = () => {
  const transDate = useDateRangeCol('TRANS_DATE', 'Date');

  const icTransReactTableCols = [
    transDate,
    {
      accessorKey: 'HIST_YEAR',
      header: 'Year',
      filterFn: 'contains',
    },
    {
      accessorKey: 'HIST_PERIOD',
      header: 'Period',
      filterFn: 'contains',
    },
    {
      accessorKey: 'SYSTEM_CD',
      header: 'System',
      filterFn: 'contains',
    },
    {
      accessorKey: 'TYPE_DESC',
      header: 'Type',
      filterFn: 'contains',
    },
    {
      accessorKey: 'QUANTITY',
      header: 'Qty',
      filterFn: 'contains',
    },
    {
      accessorKey: 'TRAN_UOM',
      header: 'UOM',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LINE_NBR',
      header: 'Line',
      filterFn: 'contains',
    },
    {
      accessorKey: 'UNIT_COST',
      header: 'Cost',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    { accessorKey: 'DOCUMENT', header: 'Document', filterFn: 'contains' },
    {
      accessorKey: 'ORIGINAL_QTY',
      header: 'Original Qty',
      filterFn: 'contains',
    },
    { accessorKey: 'TRAN_UOM_MULT', header: 'UOM Mult', filterFn: 'contains' },
    { accessorKey: 'STOCK_UOM', header: 'UOM Mult', filterFn: 'contains' },
    { accessorKey: 'REASON_CODE', header: 'Reason Code', filterFn: 'contains' },
    {
      accessorKey: 'UNIT_COST_CURR',
      header: 'Current Cost',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'ADD_UNIT_COST',
      header: 'Additional Unit Cost',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    { accessorKey: 'BIN', header: 'Bin', filterFn: 'contains' },
    { accessorKey: 'FROM_TO_BIN', header: 'From To Bin', filterFn: 'contains' },
    { accessorKey: 'FROM_TO_LOC', header: 'From To Loc', filterFn: 'contains' },
  ];

  return icTransReactTableCols;
};

export { useIcTransReactTableCols };
