import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  Grid,
  TextField,
  Paper,
  InputAdornment,
  Card,
  CardContent
} from '@material-ui/core'
import { AntTabs } from '../components/VendorTabs'
import { dateFormat } from '../utils/formatter'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { customerInvoiceCols, paymentCols, customerDistribCols } from '../constants/ArCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import ReactTable from '../components/ReactTable'
import DialogTable from '../components/DialogTable'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import { useCustomerInvoiceCols } from '../hooks/ArCols';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem',
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%',
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' },
}));

const defaultHeaders = {
  company: '',
  customer: '',
  fromDate: '',
  toDate: '',
};

export default function CustomerActivity(props) {
  const [state, setstate] = useState({
    isLoading: false,
    customerData: null,
    customerInvoices: null
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [tabVal, setTabVal] = useState(0)
  const [payment, setPayment] = useState(null)
  const [distrib, setDistrib] = useState(null)

  const classes = useStyles();

  const customerInvoiceCols = useCustomerInvoiceCols();

  const { credentials } = props.fetchInitialData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchCutomerActivityData(headers);
  };

  const fetchCutomerActivityData = async (params) => {
    setstate({ ...state, isLoading: true });

    try {
      const resp = await LambdaFetch(
        'ar-customer-activity',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-customer-activity',
          ...params,
        }),
        '',
        credentials
      );
      if (resp.data.customerData[0].length === 0) {
        props.fetchInitialData.createSnack(
          'No customer data found',
          'warning',
          3000
        );
        throw new Error('No customer data found');
      }

      const invoices = resp.data.customerInvoices.map((i) => {
        return {
          ...i,
          COMPANY: params.company,
          CUSTOMER: params.customer,
          PAYMENT_VIEW: <div className="editLink" 
          onClick={() => getPayment(i.COMPANY, i.INVOICE)}>view</div>,
          DISTRIB_VIEW: <div className="editLink" 
          onClick={() => getDistrib(i.COMPANY, i.INVOICE, i.BATCH_NBR, i.TRANS_TYPE, i.CUSTOMER)}>view</div>
        }
      })
      
      setstate({
        ...state,
        customerData: resp.data.customerData[0],
        customerInvoices: invoices,
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      setstate({ ...state, isLoading: false });
    }
  }
  const getDistrib = async (company, invoice, batch, type, customer) => {
    try {
      const resp = await LambdaFetch(
        'ar-customer-activity',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-distrib',
          company,
          invoice,
          batch,
          type,
          customer
        }),
        '',
        credentials
      )

    setDistrib(resp.data.distrib)
    } catch (e) {
      console.log(e)
    }
  }
  const getPayment = async (company, invoice) => {
    try {
      const resp = await LambdaFetch(
        'ar-customer-activity',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-payment',
          company,
          invoice,
        }),
        '',
        credentials
      );

      setPayment(resp.data.payment);
    } catch (e) {
      console.log(e);
    }
  };
  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1;
    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(dataObj[lookup]) : dataObj[lookup]}${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }
  const { customerData, customerInvoices } = state
  return (
    <>
      <DialogTable
        isOpen={!!payment}
        handleClose={() => setPayment(null)}
        tableData={payment}
        cols={paymentCols}
        fileName={`payments`}
        noFooter
      />
       <DialogTable
        isOpen={!!distrib}
        handleClose={() => setDistrib(null)}
        tableData={distrib}
        cols={customerDistribCols}
        fileName={`distrib`}
        noFooter
      />
    
      <div style={{ padding: '1.5rem 0.5rem' }}>
        <IconButton
          style={{ borderRadius: '5px', position: 'absolute' }}
          size="small"
          onClick={() => props.history.push('/')}
          aria-label="delete"
        >
          <ArrowBackIcon />
          {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
            'Dashboard'}
        </IconButton>
        <Typography variant="h5" style={{ textAlign: 'center' }}>
          Customer Activity
        </Typography>
        <div className={classes.root}>
          <Paper elevation={0} style={{ padding: '1rem' }}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                alignContent="center"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <AutocompleteDropDown
                    {...props}
                    id={'company'}
                    name={'Company'}
                    width="250px"
                    key={'company'}
                    required={true}
                    variant="standard"
                    label={'Company'}
                    value={headers.company}
                    category={'company'}
                    screen={'customer-activity'}
                    onChange={(val) =>
                      setHeaders({
                        ...headers,
                        company: val ? val.key_value : '',
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <ServerSideAutoCompletev2
                    disabled={!headers.company}
                    required={true}
                    id={'customer'}
                    width="225px"
                    label={'Customer'}
                    dontAutoHighlight
                    dontOpenOnFocus
                    accessToken={credentials.user.accessToken}
                    error={`No Customer Found`}
                    variant="standard"
                    handleChange={(id, hit) => {
                      setHeaders({ ...headers, customer: id });
                    }}
                    credentials={credentials}
                    apiResource="ar-customer-activity"
                    secondaryParams={{
                      limit: 10,
                      company: headers.company,
                      action: 'autocomplete-ar-customer',
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: '1rem' }}>
                <LoadingButton
                  label="Submit"
                  disabled={false}
                  isLoading={state.isLoading}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              </div>
            </form>
          </Paper>

          {customerData && (
            <div style={{ marginTop: '1rem ' }}>
              <Card
                style={{ marginTop: '1rem' }}
                elevation={props.inverseElevation ? 0 : 2}
              >
                <CardContent style={{ position: 'relative' }}>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '0.5rem',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div>
                      <Typography variant="h5">
                        {`${state.customerData.SEARCH_NAME} (${state.customerData.CUSTOMER})`}
                      </Typography>
                      <Typography>
                        {`Company: ${state.customerData.COMPANY}`}
                      </Typography>

                      <Typography variant="subtitle1">
                        {`Status: ${state.customerData.ACTIVE_STATUS}`}
                      </Typography>
                    </div>
                  </div>

                  <AntTabs
                    value={tabVal}
                    color={credentials.primaryAppColor}
                    handleChange={(event, newValue) => {
                      setTabVal(newValue);
                    }}
                    tabs={['Main', 'Invoices']}
                  />
                  <div style={{ marginTop: '1rem' }}>
                    {tabVal === 0 && (
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                      >
                        <Grid item xs={12} sm={6} md={6}>
                          <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h5">Main</Typography>
                            <Grid
                              container
                              spacing={0}
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="flex-start"
                            >
                              <Grid item xs={12}>
                                {printValue(
                                  customerData,
                                  'CUST_GROUP',
                                  'Customer Group'
                                )}
                                {printValue(
                                  customerData,
                                  'START_DATE',
                                  'Start Date'
                                )}
                                {printValue(
                                  customerData,
                                  'DEFAULT_CODE',
                                  'Code'
                                )}
                                {printValue(
                                  customerData,
                                  'MIN_CLASS',
                                  'Min Class'
                                )}
                                {printValue(
                                  customerData,
                                  'MAJ_CLASS',
                                  'Major Class'
                                )}
                                {printValue(
                                  customerData,
                                  'CURR_BAL',
                                  'Current Balance'
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h5">
                              Contact Information
                            </Typography>
                            <Grid
                              container
                              spacing={0}
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="flex-start"
                            >
                              <Grid item xs={12}>
                                {printValue(customerData, 'CONTACT', 'Contact')}
                                {printValue(
                                  customerData,
                                  'PHONE_NMBR',
                                  'Telephone'
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h6">
                              Address Information
                            </Typography>

                            <Grid
                              container
                              spacing={0}
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="flex-start"
                            >
                              <Grid item xs={12} sm={6}>
                                {printValue(customerData, 'ADDR1', 'Address 1')}
                                {printValue(customerData, 'ADDR2', 'Address 2')}
                                {printValue(customerData, 'ADDR3', 'Address 3')}
                                {printValue(customerData, 'ADDR4', 'Address 4')}
                                {printValue(customerData, 'CITY', 'City')}
                                {printValue(customerData, 'STATE', 'State')}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                {printValue(customerData, 'ZIP', 'Zip')}
                                {printValue(customerData, 'COUNTY', 'County')}
                                {printValue(customerData, 'COUNTRY', 'Country')}
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  {tabVal === 1 && (
                    <ReactTable
                      cols={customerInvoiceCols}
                      data={customerInvoices}
                    />
                  )}
                </CardContent>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
