import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  Grid,
  Button,
  Paper,
  InputAdornment,
  TableFooter,
  TableRow,
  TableCell,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LambdaFetch from '../functions/FetchFromLambda';
import LoadingButton from '../components/LoadingButton';
import { am70Cols } from '../constants/AssetCols';
import TableMui from '../components/TableMui';
import LoadingCircle from '../components/common/LoadingCircle';
import SimpleAutoComplete from '../functions/SimpleAutoComplete';
import getTableOptions from '../constants/TableOptions';
import { smallScreenWidth } from '../constants/AppConstants';
import clsx from 'clsx';
import { currencyTextFormat } from '../utils/formatter';
import AssetDetail from '../components/AssetDetail';
import ReactTable from '../components/ReactTable';
import { useAm70ReactTableCols } from '../hooks/AssetCols';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem',
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999,
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0',
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: (props) => props.primaryColor,
    height: '60px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const startAdornment = (
  <>
    <InputAdornment position="start">
      <div></div>
    </InputAdornment>
  </>
);
const defaultHeaders = {
  company: '',
  leaseCompany: '',
  lease: '',
  location: '',
  division: '',
  assetType: '',
  subType: '',
  assetGroup: '',
};
export default function AssetAnalysis(props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    fetched: false,
  });
  const [headers, setHeaders] = useState(defaultHeaders);
  const [headerOptions, setHeaderOptions] = useState({
    company: [],
    leaseCompany: [],
    lease: [],
    location: [],
    division: [],
    assetType: [],
    subType: [],
    assetGroup: [],
  });
  const [clearHeader, setClearHeaders] = React.useState(false);
  const [assetData, setAssetData] = useState({
    open: false,
    assetData: null,
    items: [],
    books: [],
  });

  const am70ReactTableCols = useAm70ReactTableCols();

  const { credentials } = props.fetchInitialData;
  const classes = useStyles({ primaryColor: credentials.primaryAppColor });

  useEffect(() => {
    async function fetchMyAPI() {
      fetchData();
    }
    fetchMyAPI();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'asset-analysis',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-headers',
        }),
        '',
        credentials
      );

      setstate({ ...state, fetched: true });
      setHeaderOptions({ ...headerOptions, ...resp.data });
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setstate({ ...state, isLoading: true });
      const resp = await LambdaFetch(
        'asset-analysis',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'am70_read',
          ...headers,
        }),
        '',
        credentials
      );
      setstate({
        ...state,
        isLoading: false,
        data: resp.data.am70.map((row) => {
          return {
            ...row,
            ASSET_LINK: (
              <div
                className="editLink"
                onClick={() => getAsset(row.COMPANY, row.ASSET)}
              >
                {row.ASSET}
              </div>
            ),
          };
        }),
      });
    } catch (e) {
      console.log(e);
      setstate({ ...state, isLoading: false });
    }
  };

  const handleChange = (name, value) => {
    setHeaders({ ...headers, [name]: !!value ? value.id : null });
  };

  const options = getTableOptions({
    cols: am70Cols,
    fileName: 'Asset_Analysis',
  });

  if (!state.fetched) {
    return <LoadingCircle />;
  }

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const customBodyFoot = (opts) => {
    let tot = opts.data.reduce((acc, cur) => {
      acc += parseFloat(cur.data[11].props.value);
      return acc;
    }, 0);
    return (
      <TableFooter className={footerClasses}>
        <TableRow
          style={{ borderTop: 'solid black 1px' }}
          className={footerClasses}
        >
          <TableCell
            size="small"
            colSpan={10}
            className={footerClasses}
          ></TableCell>
          <TableCell
            className={footerClasses}
            size="small"
            align="right"
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            Total:{' '}
          </TableCell>
          <TableCell
            className={footerClasses}
            size="small"
            align="right"
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            {`${currencyTextFormat(tot)}`}
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  };

  const getAsset = async (comp, id) => {
    try {
      if (!id || !comp) return null;
      setAssetData({ ...assetData, open: true });
      const resp = await LambdaFetch(
        'assets',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'asset-get',
          company: comp,
          asset: id,
        }),
        '',
        credentials
      );
      const assetDesc = resp.data.asset[0].ASSET_DESC
      setAssetData({
        ...assetData,
        open: true,
        assetData: resp.data.asset[0],
        items: resp.data.items.map(row => {
          return {
            ...row,
            COMPANY: comp,
            ASSET: id,
            ASSET_DESC: assetDesc
          }
        }),
        books: resp.data.books.map(row => {
          return {
            ...row,
            COMPANY: comp,
            ASSET: id,
            ASSET_DESC: assetDesc
          }
        })
      })
    } catch (e) {
      console.log(e);
    }
  };
  am70Cols[11].options.setCellHeaderProps = () => {
    return {
      className: clsx({
        [classes.rightTableHead]: true,
      }),
    };
  };

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <Dialog
        open={assetData.open}
        TransitionComponent={Transition}
        maxWidth="xl"
        fullWidth
        fullScreen={
          props.fetchInitialData.credentials.appWidth < smallScreenWidth
            ? true
            : false
        }
        scroll="body"
        keepMounted
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                setAssetData({
                  ...assetData,
                  assetData: null,
                  items: [],
                  books: [],
                  open: false,
                })
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {assetData.open && (
          <DialogContent style={{ padding: 0 }}>
            {!!assetData.assetData ? (
              <AssetDetail
                {...props}
                assetData={assetData.assetData}
                items={assetData.items}
                books={assetData.books}
              />
            ) : (
              <LoadingCircle />
            )}
          </DialogContent>
        )}
      </Dialog>

      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size="small"
        onClick={() => props.history.push('/')}
        aria-label="delete"
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Asset Analysis (AM70.1)
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              {[
                {
                  id: 'company',
                  label: 'Company',
                  optionLabel: (val) => `${val.id}`,
                  optionRender: null,
                  required: true,
                },
                {
                  id: 'leaseCompany',
                  label: 'Lease Company',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
                {
                  id: 'lease',
                  label: 'Lease',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
                {
                  id: 'location',
                  label: 'Location',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
                {
                  id: 'division',
                  label: 'Division',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
                {
                  id: 'assetType',
                  label: 'Asset Type',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
                {
                  id: 'subType',
                  label: 'Sub Type',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
                {
                  id: 'assetGroup',
                  label: 'Group',
                  optionLabel: (val) => `${val.description}`,
                  optionRender: null,
                  required: false,
                },
              ].map((field) => {
                const renderOp = !!field.optionLabel
                  ? field.optionLabel
                  : (option) => {
                      return (
                        <div>
                          <Typography
                            style={{ fontSize: '13px' }}
                            variant="body1"
                            color="textPrimary"
                          >
                            {`${option.id}`}
                          </Typography>
                        </div>
                      );
                    };
                const optionLabel = field.optionLabel
                  ? field.optionLabel
                  : (val) => `${val.id}`;
                return (
                  <Grid key={field.id} item>
                    <SimpleAutoComplete
                      id={field.id}
                      required={field.required}
                      name={field.id}
                      width="170px"
                      variant="standard"
                      label={field.label}
                      value={headers[field.id]}
                      onChange={(val) => handleChange(field.id, val)}
                      options={headerOptions[field.id]}
                      getOptionLabel={optionLabel}
                      renderOption={renderOp}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label="Submit"
                isLoading={state.isLoading}
                color="primaryVLButton"
                buttonType="submit"
              />
            </div>
          </form>
        </Paper>

        {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={am70ReactTableCols} data={state.data} />
            {/* <TableMui
              cols={am70Cols}
              data={state.data}
              options={{
                ...options,
                customTableBodyFooterRender: customBodyFoot
              }}
              title=''
            /> */}
          </Grid>
        )}
      </div>
    </div>
  );
}
