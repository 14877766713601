import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import {
    poreceiptsReactTableCols
} from '../constants/PoCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import { ServerSideTable } from '../components/ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ReactTable from '../components/ReactTable'


const _ = require('lodash')

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  company: '',
  fromDate: '',
  toDate: '',
  poCode: '',
  poNumber: '',
  poRelease: '',
}

export default function PoReceipt (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    fetched: true,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [showTable, setShowTable] = useState(false)
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleSubmit = async e => {
    e.preventDefault()
    try {
        setstate({ ...state, isLoading: true })
        const resp = await LambdaFetch(
          'po-inquiry',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'po-receipt',
            ...headers
          }),
          '',
          credentials
        )
        setstate({
          ...state,
          data: resp.data.receipts,
          isLoading: false
        })
      } catch (e) {
        console.log(e)
      }
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Purchase Order Receipt
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'po'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={!headers.company}
                  required={true}
                  id='poNumber'
                  width='175px'
                  label='PO Number'
                  accessToken={credentials.user.accessToken}
                  error='No PO found'
                  variant='standard'
                  handleChange={(id, hit) => {
                        setHeaders({ ...headers, poNumber: id })
                  }}
                  credentials={credentials}
                  apiResource='po'
                  secondaryParams={{
                    action: 'autocomplete-po',
                    limit: 10,
                    company: headers.company
                  }}
                  dontOpenOnFocus
                />
              </Grid>

    <Grid item style={{ marginTop: '5px' }}>
                <TextField
                  id='poRelease'
                  label='Release'
                  style={{ width: '125px' }}
                  value={headers.poRelease}
                  onChange={e =>
                    setHeaders({ ...headers, poRelease: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position='start'>
                          <div></div>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: '5px' }}>
                <TextField
                  id='poCode'
                  label='PO Code'
                  style={{ width: '125px' }}
                  value={headers.poCode}
                  onChange={e =>
                    setHeaders({ ...headers, poCode: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position='start'>
                          <div></div>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>


              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{ marginTop: '5px', width: '160px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label="Submit"
                isLoading={state.isLoading}
                color="primaryVLButton"
                buttonType="submit"
              />
              {!!(
                headers.company || 
                headers.fromDate || 
                headers.toDate || 
                headers.vendorGroup || 
                headers.vendor || 
                headers.vendorLoc || 
                headers.buyer || 
                headers.status || 
                headers.poCode || 
                headers.poNumber || 
                headers.poRelease || 
                headers.shipTo || 
                headers.lines || 
                headers.amount 
              ) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader);
                    setHeaders(defaultHeaders);
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>

        
          </form>
        </Paper>
        {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={poreceiptsReactTableCols} data={state.data} />
          </Grid>
        )}
      </div>
    </div>
  )
}
