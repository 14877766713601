import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => props.primaryColor,
    height: '60px'
  }
}))

const Transition = React.forwardRef(function Transition (props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
  })

export default function AssetDetail (props) {
  const classes = useStyles()

  const { handleClose, open, fetchInitialData, data, children } = props
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth='xl'
      fullWidth
      fullScreen={
        fetchInitialData.credentials.appWidth < smallScreenWidth ? true : false
      }
      scroll='body'
      keepMounted
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {open && (
        <DialogContent style={{ padding: 0 }}>
          {!!data ? children : <LoadingCircle />}
        </DialogContent>
      )}
    </Dialog>
  )
}
