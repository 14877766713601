import { currencyFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const useAm80ReactTableServerSideCols = () => {
  const transDateCol = useDateRangeCol(
    'TRANS_DATE', // TODO
    'Date'
  ); // TODO Invoice Date

  const am80ReactTableServerSideCols = [
    transDateCol,
    {
      accessorKey: 'BOOK',
      header: 'Book',
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'COMPANY',
      header: 'Company',
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'ASSET_LINK',
      header: 'Asset',
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'ASSET_PROCESS',
      header: 'Process',
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'REFERENCE',
      header: 'Reference',
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'ACCT_UNIT',
      header: 'Acct Unit',
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'ACCOUNT',
      header: 'Acct',
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'SUB_ACCOUNT',
      header: 'Sub Acct',
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'TRANS_AMOUNT',
      header: 'Amount',
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
  ];

  return am80ReactTableServerSideCols;
};

const useItemCols = () => {
  const purchaseDateCol = useDateRangeCol('PURCHASE_DATE', 'Purchase date');

  const itemReactTableCols = [
    {
      accessorKey: 'COMPANY',
      header: 'Company',
    },
    {
      accessorKey: 'ASSET',
      header: 'Asset',
    },
    {
      accessorKey: 'ASSET_DESC',
      header: 'Asset Name',
    },
    {
      accessorKey: 'ITEM_NBR',
      header: 'Item Number',
      filterFn: 'contains',
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Description',
      filterFn: 'contains',
    },
    purchaseDateCol,
    {
      accessorKey: 'ITEM_QUANTITY',
      header: 'Qty',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ITEM_COST_BASE',
      header: 'Cost',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
  ];
  return itemReactTableCols;
};

const useBookCols = () => {
  const insrvDateCol = useDateRangeCol('INSRV_DATE', 'Date');
  const bookBasisCol = useDateRangeCol('BOOK_BASIS', 'Basis');
  const deprYTDCol = useDateRangeCol('DEPR_YTD', 'YTD');
  const deprLTDCol = useDateRangeCol('DEPR_LTD', 'LTD');
  const bookValueCol = useDateRangeCol('BOOK_VALUE', 'Book');

  const bookReactTableCols = [
    {
      accessorKey: 'BOOK',
      header: 'Book',
      filterFn: 'contains',
    },
    {
      accessorKey: 'BOOK_STATUS',
      header: 'Status',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LIFE',
      header: 'Life',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LIFE_REM',
      header: 'Remaining',
      filterFn: 'contains',
    },
    insrvDateCol,
    {
      accessorKey: 'METHOD',
      header: 'Method',
      filterFn: 'contains',
    },
    {
      accessorKey: 'CONVENTION',
      header: 'Convention',
      filterFn: 'contains',
    },
    {
      accessorKey: 'CONVENTION_TXT',
      header: 'Convention Desc',
      filterFn: 'contains',
    },
    bookBasisCol,
    deprYTDCol,
    deprLTDCol,
    bookValueCol,
  ];

  return bookReactTableCols;
};

const useAm70ReactTableCols = () => {
  const purchaseDateCol = useDateRangeCol('PURCHASE_DATE', 'Purchase date');

  const am70ReactTableCols = [
    {
      accessorKey: 'ASSET',
      header: 'Asset',
      Cell: ({ cell }) => {
        return (
        <span>{cell.row.original.ASSET_LINK}</span>
        )
      }
    },
    {
      accessorKey: 'TAG_NBR',
      header: 'Tag',
      filterFn: 'contains',
    },
    purchaseDateCol,
    {
      accessorKey: 'DESCRIPTION',
      header: 'Desc',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LEASE_COMPANY',
      header: 'Lease Comp',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LEASE',
      header: 'Lease',
      filterFn: 'contains',
    },
    {
      accessorKey: 'LOCATION_NAME',
      header: 'Loc',
      filterFn: 'contains',
    },
    {
      accessorKey: 'DIVISION',
      header: 'Div',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ASSET_TYPE',
      header: 'Type',
      filterFn: 'contains',
    },
    {
      accessorKey: 'SUB_TYPE',
      header: 'Sub Type',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ASSET_GROUP',
      header: 'Group',
      filterFn: 'contains',
    },
    {
      accessorKey: 'ITEM_COST_TRAN',
      header: 'Cost',
      filterFn: 'contains',
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
  ];

  return am70ReactTableCols;
};

export {
  useAm80ReactTableServerSideCols,
  useItemCols,
  useBookCols,
  useAm70ReactTableCols,
};
