import { currencyFormat, dateFormat, noWrapFormat } from '../utils/formatter';

export const itemCols = [
  {
    name: 'ITEM_NBR',
    label: 'Item Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'PURCHASE_DATE',
    label: 'Purchase Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ITEM_QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ITEM_COST_BASE',
    label: 'Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
];

export const itemReactTableCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
  },
  {
    accessorKey: 'ASSET',
    header: 'Asset',
  },
  {
    accessorKey: 'ASSET_DESC',
    header: 'Asset Name',
  },
  {
    accessorKey: 'ITEM_NBR',
    header: 'Item Number',
    filterFn: 'contains',
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    filterFn: 'contains',
  },
  {
    accessorKey: 'PURCHASE_DATE',
    header: 'Purchase Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue()),
  },
  {
    accessorKey: 'ITEM_QUANTITY',
    header: 'Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ITEM_COST_BASE',
    header: 'Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'ITEM_TAX_BASE', 
    header: 'Tax Base',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
	{
    accessorKey: 'ITEM_TAX_TRAN', 
    header: 'Tax Tran',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'PO_NBR', 
    header: 'Po',
    filterFn: 'contains',
  },
  {
    accessorKey: 'PO_RELEASE', 
    header: 'Po Release',
    filterFn: 'contains',
  },
  {
    accessorKey: 'PO_CODE', 
    header: 'Po Code',
    filterFn: 'contains',
  },
  {
    accessorKey: 'VENDOR', 
    header: 'Vendor',
    filterFn: 'contains',
  },
  {
    accessorKey: 'VENDOR_NAME', 
    header: 'Vendor Name',
    filterFn: 'contains',
  },
  {
    accessorKey: 'INVOICE', 
    header: 'Invoice',
    filterFn: 'contains',
  },
  {
    accessorKey: 'INV_DATE', 
    header: 'Invoice Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'INV_COMPANY', 
    header: 'Invoice Company',
    filterFn: 'contains',
  },
]

export const bookCols = [
  {
    name: 'BOOK',
    label: 'Book',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BOOK_STATUS',
    label: 'Status',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'LIFE',
    label: 'Life',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'LIFE_REM',
    label: 'Remaining',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'INSRV_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'METHOD',
    label: 'Method',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'CONVENTION',
    label: 'Convention',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'CONVENTION_TXT',
    label: 'Convention Desc',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BOOK_BASIS',
    label: 'Basis',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DEPR_YTD',
    label: 'YTD',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DEPR_LTD',
    label: 'LTD',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BOOK_VALUE',
    label: 'Book',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
];

export const bookReactTableCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
  },
  {
    accessorKey: 'ASSET',
    header: 'Asset',
  },
  {
    accessorKey: 'ASSET_DESC',
    header: 'Asset Name',
  },
  {
    accessorKey: 'BOOK',
    header: 'Book',
    filterFn: 'contains',
  },
  {
    accessorKey: 'BOOK_STATUS',
    header: 'Status',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LIFE',
    header: 'Life',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LIFE_REM',
    header: 'Remaining',
    filterFn: 'contains',
  },
  {
    accessorKey: 'INSRV_DATE',
    header: 'Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue()),
  },
  {
    accessorKey: 'METHOD',
    header: 'Method',
    filterFn: 'contains',
  },
  {
    accessorKey: 'CONVENTION',
    header: 'Convention',
    filterFn: 'contains',
  },
  {
    accessorKey: 'CONVENTION_TXT',
    header: 'Convention Desc',
    filterFn: 'contains',
  },
  {
    accessorKey: 'BOOK_BASIS',
    header: 'Basis',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
  {
    accessorKey: 'DEPR_YTD',
    header: 'YTD',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
  {
    accessorKey: 'DEPR_LTD',
    header: 'LTD',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
  {
    accessorKey: 'BOOK_VALUE',
    header: 'Book',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
];
export const am80Cols = [
  {
    name: 'TRANS_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BOOK',
    label: 'Book',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true,
      display: false,
    },
  },
  {
    name: 'ASSET_LINK',
    label: 'Asset',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ASSET_PROCESS',
    label: 'Process',
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT_UNIT',
    label: 'Acct Unit',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT',
    label: 'Acct',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Acct',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'TRANS_AMOUNT',
    label: 'Amount',
    options: {
      setCellProps: (value) => {
        return {
          style: { textAlign: 'right' },
        };
      },
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
];

export const am80ServerSideCols = [
  {
    name: 'TRANS_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'BOOK',
    label: 'Book',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ASSET_LINK',
    label: 'Asset',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ASSET_PROCESS',
    label: 'Process',
    options: {
      filter: false,
      sort: false,
    },
  },

  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'ACCT_UNIT',
    label: 'Acct Unit',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'ACCOUNT',
    label: 'Acct',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Acct',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'TRANS_AMOUNT',
    label: 'Amount',
    options: {
      setCellProps: (value) => {
        return {
          style: { textAlign: 'right' },
        };
      },
      customBodyRender: currencyFormat,
      filter: false,
      sort: true,
    },
  },
];

export const am70Cols = [
  {
    name: 'ASSET_LINK',
    label: 'Asset',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'TAG_NBR',
    label: 'Tag',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'PURCHASE_DATE',
    label: 'Purchase Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DESCRIPTION',
    label: 'Desc',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'LEASE_COMPANY',
    label: 'Lease Comp',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'LEASE',
    label: 'Lease',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'LOCATION_NAME',
    label: 'Loc',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DIVISION',
    label: 'Div',
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: 'ASSET_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SUB_TYPE',
    label: 'Sub Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ASSET_GROUP',
    label: 'Group',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ITEM_COST_TRAN',
    label: 'Cost',
    options: {
      customBodyRender: currencyFormat,
      setCellProps: (value) => {
        return {
          style: { textAlign: 'right' },
        };
      },
      filter: true,
      sort: true,
    },
  },
];

export const am70ReactTableCols = [
  {
    accessorKey: 'ASSET',
    header: 'Asset',
    Cell: ({ cell }) => {
      return (
      <span>{cell.row.original.ASSET_LINK}</span>
      )
    }
  },
  {
    accessorKey: 'TAG_NBR',
    header: 'Tag',
    filterFn: 'contains',
  },
  {
    accessorKey: 'PURCHASE_DATE',
    header: 'Purchase Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue()),
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Desc',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LEASE_COMPANY',
    header: 'Lease Comp',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LEASE',
    header: 'Lease',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LOCATION_NAME',
    header: 'Loc',
    filterFn: 'contains',
  },
  {
    accessorKey: 'DIVISION',
    header: 'Div',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ASSET_TYPE',
    header: 'Type',
    filterFn: 'contains',
  },
  {
    accessorKey: 'SUB_TYPE',
    header: 'Sub Type',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ASSET_GROUP',
    header: 'Group',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ITEM_COST_TRAN',
    header: 'Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
];

export const am80ReactTableServerSideCols = [
  {
    accessorKey: 'TRANS_DATE',
    header: 'Date',
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => dateFormat(cell.getValue()),
  },
  {
    accessorKey: 'BOOK',
    header: 'Book',
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'ASSET_LINK',
    header: 'Asset',
    enableSorting: false,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'ASSET_PROCESS',
    header: 'Process',
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'REFERENCE',
    header: 'Reference',
    enableSorting: false,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'ACCT_UNIT',
    header: 'Acct Unit',
    enableSorting: false,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'ACCOUNT',
    header: 'Acct',
    enableSorting: false,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'SUB_ACCOUNT',
    header: 'Sub Acct',
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'TRANS_AMOUNT',
    header: 'Amount',
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => currencyFormat(cell.getValue()),
  },
];
