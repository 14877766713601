import React, { useEffect, useState } from 'react'
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Slide
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { Button } from '@material-ui/core'
import { currencyTextFormat } from '../utils/formatter'
import { AntTabs } from '../components/VendorTabs'
import SimpleTable from '../components/common/SimpleTable'
import {dateFormat} from '../utils/formatter';

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function ItemDetail (props) {
  const [tabVal, setTabVal] = useState(0)

  const classes = useStyles()
  const { credentials } = props.fetchInitialData

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }

  const { itemData } = props
  
  const oumRows = [
    ...Array(9)
      .fill('')
      .map((r, index) => {
        const row = {
          UOM: itemData[`ALT_UOM_0${index + 1}`],
          'Conv Factor': itemData[`ALT_UOM_CONV_0${index + 1}`],
          Tracking: itemData[`TRACKING_FL_0${index + 1}`],
          Trans: itemData[`TRANS_FL_0${index + 1}`],
          Sell: itemData[`SELL_FL_0${index + 1}`],
          'Sell Price': itemData[`SELL_PRICE_FL_0${index + 1}`],
          Buy: itemData[`BUY_FL_0${index + 1}`]
        }

        return row
      }),
    {
      UOM: itemData[`ALT_UOM_10`],
      'Conv Factor': itemData[`ALT_UOM_CONV_10`],
      Tracking: itemData[`TRACKING_FL_10`],
      Trans: itemData[`TRANS_FL_10`],
      Sell: itemData[`SELL_FL_10`],
      'Sell Price': itemData[`SELL_PRICE_FL_10`],
      Buy: itemData[`BUY_FL_10}`]
    }
  ]

  return (
    <>
      <Card elevation={props.inverseElevation ? 0 : 2}>
        <CardContent style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              marginTop: '0.5rem',
              justifyContent: 'flex-start'
            }}
          >
            <div>
              <Typography variant='h5'>{`Item: ${itemData.ITEM}`}</Typography>
              <Typography variant='subtitle1'>{`Item Group: ${itemData.ITEM_GROUP}`}</Typography>
              <Typography variant='subtitle2'>{`Description: ${itemData.DESCRIPTION}`}</Typography>
            </div>
          </div>
          <div style={{ marginTop: '1rem' }}>
              <Grid
                container
                spacing={1}
                direction='row'
                justify='flex-start'
                alignItems='stretch'
              >
                <Grid item xs={12}>
                  <Paper elevation={2} className={classes.paper}>
                    <Typography variant='h6'>Main</Typography>

                    <Grid
                      container
                      spacing={0}
                      direction='row'
                      justify='space-evenly'
                      alignItems='flex-start'
                    >
                      <Grid item xs={12} sm={6}>
                        {printValue(itemData, 'ADDED_DATE', 'Date Added')}
                        {printValue(itemData, 'STOCK_UOM', 'Stock UOM')}
                        {printValue(
                          itemData,
                          'MANUF_CODE',
                          'Manufacturer Info',
                          'MANUF_NBR'
                        )}
                       
                      </Grid>

                      <Grid item xs={12} sm={6}>

                      {printValue(itemData, 'GENERIC', 'Generic Name')}
                        {printValue(
                          itemData,
                          'REPLACE_ITEM',
                          'Replacement Item'
                        )}

                        {printValue(
                          itemData,
                          'COMMODITY_CODE',
                          'Commodity Code'
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper elevation={2} className={classes.paper}>
                    <Typography variant='h6'>Classes</Typography>

                    {printValue(itemData, 'INVENTORY_CLASS', 'Inventory')}
                    {printValue(itemData, 'PURCH_CLASS', 'Purchasing')}
                    {printValue(itemData, 'SALES_CLASS', 'Sales')}
                    {printValue(itemData, 'FREIGHT_CLASS', 'Freight')}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper elevation={2} className={classes.paper}>
                    <Typography variant='h6'>UNSPSC</Typography>

                    {printValue(itemData, 'ICSEGMENT', 'Segment')}
                    {printValue(itemData, 'ICFAMILY', 'Family')}
                    {printValue(itemData, 'ICCLASS', 'Class')}
                    {printValue(itemData, 'ICCOMMODITY', 'Commodity')}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper elevation={2} className={classes.paper}>
                    <Typography variant='h6'>Item Details</Typography>

                    {printValue(itemData, 'WEIGHT', 'Weight')}
                    {printValue(itemData, 'CUBIC_FEET', 'Cubic Feet')}
                    {printValue(itemData, 'COMMODITY_CODE', 'Commodity Code')}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Paper elevation={2} className={classes.paper}>
                    <SimpleTable
                      style={{ fontSize: '12px' }}
                      dense
                      headers={[
                        'UOM',
                        'Conv Factor',
                        'Tracking',
                        'Trans',
                        'Sell',
                        'Sell Price',
                        'Buy'
                      ]}
                      rows={oumRows}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper elevation={2} className={classes.paper}>
                    <Typography variant='h6'>User Fields</Typography>

                    {printValue(itemData, 'USER_FIELD1', 'User Field 1')}
                    {printValue(itemData, 'USER_FIELD2', 'User Field 2')}
                    {printValue(itemData, 'USER_FIELD3', 'User Field 3')}
                    {printValue(itemData, 'USER_FIELD4', 'User Field 4')}
                    {printValue(itemData, 'USER_FIELD5', 'User Field 5')}
                    {printValue(itemData, 'USER_FIELD_N1', 'User Field N1')}
                    {printValue(itemData, 'USER_FIELD_N2', 'User Field N2')}
                    {printValue(itemData, 'USER_FIELD_N3', 'User Field N3')}
                  </Paper>
                </Grid>
              </Grid>
            
            {tabVal === 1 && (
              <Grid
                container
                spacing={1}
                direction='row'
                justify='flex-start'
                alignItems='stretch'
              >
                <Grid item xs={12}>
                  <Paper elevation={0} className={classes.paper}>
                    <Grid
                      container
                      spacing={0}
                      direction='row'
                      justify='space-evenly'
                      alignItems='flex-start'
                    >
                      <Grid item xs={12} sm={6}>
                        {printValue(
                          itemData,
                          'INVENTORY_CLASS',
                          'Inventory Class'
                        )}
                        {printValue(
                          itemData,
                          'PURCH_CLASS',
                          'Purchasing Class'
                        )}
                        {printValue(itemData, 'SALES_CLASS', 'Sales Class')}
                        {printValue(itemData, 'FREIGHT_CLASS', 'Freight Class')}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {printValue(itemData, 'ICSEGMENT', 'Segment')}
                        {printValue(itemData, 'ICFAMILY', 'Family')}
                        {printValue(itemData, 'ICCLASS', 'Class')}
                        {printValue(itemData, 'ICCOMMODITY', 'Commodity')}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            )}

            {tabVal === 2 && (
              <Grid
                container
                spacing={1}
                direction='row'
                justify='flex-start'
                alignItems='stretch'
              >
                <Grid item xs={12}>
                  <Paper elevation={0} className={classes.paper}>
                    <Grid
                      container
                      spacing={0}
                      direction='row'
                      justify='space-evenly'
                      alignItems='flex-start'
                    >
                      <Grid item xs={12}>
                        {printValue(
                          itemData,
                          'STOCK_UOM',
                          'Stock Unit of Measure'
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleTable
                          dense
                          headers={[
                            'UOM',
                            'Conv Factor',
                            'Tracking',
                            'Trans',
                            'Sell',
                            'Sell Price',
                            'Buy'
                          ]}
                          rows={oumRows}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  )
}

/*
{printValue(itemData, '', '')}
ADDED_DATE: "2016-03-09T00:00:00.000Z"

COMMODITY_CODE: ""
CUBIC_FEET: 0
DESCRIPTION: "COPIER PAPER FOR REQS TO CHKS"
DESCRIPTION2: ""
DESCRIPTION3: 
EFFECT_DT: "1753-01-01T00:00:00.000Z"
EXPIRE_DT: "1753-01-01T00:00:00.000Z"
FREIGHT_CLASS: ""
GENERIC: ""
ICCLASS: ""
ICCODE_SDESC: null
ICCOMMODITY: ""
ICFAMILY: ""
ICSEGMENT: ""
INVENTORY_CLASS: "-"
ISS_ACCOUNT: 0
ISS_SUB_ACCT: 0
ITEM: "1"
ITEM_GROUP: "HOSP"
MANUF_CODE: ""
MANUF_DIVISION: ""
MANUF_NBR: ""
PURCH_CLASS: "-"
REPLACE_ITEM: ""
SALES_CLASS: "-"
STOCK_UOM: "BX"
WEIGHT: 0
*/
