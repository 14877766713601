import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  TextField,
  MenuItem,
  Grid
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { testCols } from '../constants/GlCols'
import { ServerSideTable } from '../components/ServerSideTable'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

export default function ServerSideTestTable (props) {
  const [params, setParams] = useState({ chartName: 'ACMECHART' })

  const classes = useStyles()

  return (
    <div style={{ margin: '1.5rem auto' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        Forms
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Test Form
      </Typography>

      <div className={classes.root}>
        <TextField
          style={{ margin: '1rem', width: '200px' }}
          id='standard-select-currency'
          select
          label='Chart'
          value={params.chartName}
          onChange={e => setParams({ ...params, chartName: e.target.value })}
        >
          {[
            'ACMECHART',
            'CANADA',
            'CGICHART',
            'LGECHART',
            'MWO',
            'MWO1',
            'MWO10',
            'MWO11',
            'MWO12',
            'MWO13',
            'MWO14',
            'MWO15',
            'MWO2',
            'MWO3',
            'MWO4',
            'MWO5',
            'MWO6',
            'MWO7',
            'MWO8',
            'MWO9',
            'RETAIL'
          ].map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <Grid container spacing={1}>


          <Grid item xs={12} style={{ position: 'relative' }}>
            {params.chartName && (
              <ServerSideTable
                {...props}
                apiResource='gl'
                tableColumns={testCols}
                params={params}
                tableTitle={params.chartName}
                rowsPerPage={15}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
